<template>
    <div>
        <s-toolbar dense dark color="primary"  @close="close()" save close label="Permisos"></s-toolbar>
        <v-card class="pa-md-4 mx-lg-auto">
            <v-row>
                <v-col  style="padding-bottom: 10;">
                    <v-card-title class="primary--text">Listado de Trabajadores</v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs class="ma-0 pa-0">
                            <v-row>
                                <v-col class="pl-0 pt-0">
                                    <v-data-table
                                        dense
                                        :headers="headerProductionGroupD"
                                        :items="itemsDataPG"
                                        item-key="PgdID"
                                        hide-default-footer
                                        :items-per-page="-1"
                                        disable-sort
                                        show-select
                                        class="elevation-1"
                                        height="180"  
                                    >
                                        <template v-slot:item.PpgMotive="{ item }">
                                            <s-select-definition
                                                v-model="item.PpgMotive"
                                                :def="1428">

                                            </s-select-definition>
                                        </template>
                                        <template v-slot:item.savePermission="{item}">
                                            <v-btn 
                                                color="success"
                                                dark
                                                :disabled="false"
                                                icon 
                                                small
                                                @click="savePermission(item)">
                                                <v-icon>mdi-send</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.PpgExitHour="{item}">
                                            <datetime 
                                                title="Hora  :  Minutos" 
                                                valueZone="America/Lima"  
                                                type="time" 
                                                v-model="item.PpgExitHour"
                                                ref="txtEntry"
                                                >
                                            </datetime>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-title class="primary--text">Salidas y Retornos</v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs class="ma-0 pa-0">
                            <v-row>
                                <v-col class="pl-0 pt-0">
                                    <v-data-table
                                        :headers="headerLeaveAndReturnPerson"
                                        :items="itemsLeaveAndReturnPerson"
                                        item-key="PpgID"
                                        :items-per-page="-1"
                                        disable-sort 
                                        hide-default-footer
                                        class="elevation-1"
                                        dense 
                                        height="200"  
                                        >
                                        <template v-slot:item.details="{ item }">
                                            <v-btn color="info" dark icon
                                                small fab
                                                @click="openEditHourEntry(item)">
                                                <v-icon medium>fa-regular fa-clock</v-icon>
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.timePermissions="{ item }">
                                            <v-chip x-small  :color="item.timePermissions !='' ? 'warning': 'error'" >
                                                {{ item.timePermissions }}
                                            </v-chip>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog
            persistent
            v-model="showEntryHour"
            transition="dialog-bottom-transition"
            width="50%"
            v-if="showEntryHour"
            >
            <v-card class="elevation-0">
                <s-toolbar     
                label="Registrar Hora de Retorno"
                dark
                close
                @close="closeDialog()"
                save 
                @save="saveEntryHour()"
                color="primary">
                </s-toolbar>
                <v-container>
                    <v-row>
                        <v-col cols="3" lg="3" md="6" sm="12">							
                            <label slot="before">Hora</label>
                            <div class="relojito">
                                <datetime 
                                    title="Hora  :  Minutos" 
                                    valueZone="America/Lima"  
                                    type="time" 
                                    v-model="PpgEntryHour"
                                    ref="txtEntry"
                                    >
                                </datetime>
                            </div>            
                        </v-col> 
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

    import _sProductionGroupService from "@/services/FrozenProduction/FrzProductionGroupService.js"; // grupos de trabajo - Asignar rol
    
    import _sPermissionsPersonByGroup from '@/services/FrozenProduction/FrzPermissionsPersonByGroup.js';
    import { Datetime } from 'vue-datetime';
    export default{        
        components: {Datetime},
        props:
        {
            itemdetaillineprocess: null,
        },
        data(){
            return{
                PpgEntryHour: null,
                showEntryHour: false,
                itemUpdate:{},
                itemsDataPG:[],
                headerProductionGroupD: "",
                headerProductionGroupD: [
                    { text:"item", value :"Line", width: 30},                    
                    { text: "Nombre", value: "PgdName", width: 100},
                    { text: "Apellidos", value: "PgdLastName", width: 100},
                    { text: "DNI", value: "PrsDocumentNumber", width: 100}, 
                    { text: "Hora Salida", value: "PpgExitHour", width: 100}, 
                    { text: "Permisos", value: "PpgMotive", width: 100 },
                    { text: "Guardar Permiso", value: "savePermission", width: 100},          
                ],
                headerLeaveAndReturnPerson: [
                    {text: "Hora", value: "details", width: 20 },
                    {text:"ID", value:"PpgID"},
                    {text:"Grupo de Produccion", value:"PdgNameGroup"},
                    {text:"Trabajador", value:"DatePerson"},
                    {text:"Tipo de Permiso", value: "TypePermissions"},
                    // {text:"Hora de Salida", value:"PpgExitHour"},
                    {text:"Hora de Salida", value:"exitHour"},
                    // {text:"Hora de Entrada", value:"PpgEntryHour"},
                    {text:"Hora de Retorno", value:"entryHour"},
                    {text:"Duracion de Permiso", value:"timePermissions"},
                ],
                itemsLeaveAndReturnPerson:[],
            }
        },
        methods:{
            close()
            {
                this.$emit("modalPermissionsPerson");
            },
            initialize(){
                
                _sProductionGroupService.listdetail( this.itemdetaillineprocess , this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {

                        r.data.forEach((el, index) => {
                            el.Line = index + 1;
                        });
                        
                        this.itemsDataPG = r.data;
                    }                   
                })

                _sPermissionsPersonByGroup.listgrouptable(this.itemdetaillineprocess, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemsLeaveAndReturnPerson = r.data;                                        
                    }
                });     
            },
            savePermission(item)
            {                
                item.CttID = this.itemdetaillineprocess.CttID;
                item.ClpID = this.itemdetaillineprocess.ClpID;                            
                if(item.PpgExitHour == 0)
                {
                    this.$fun.alert("Ingrese Hora de salida", "warning");                    
                    return;
                }
                this.$fun.alert("Esta seguro de guardar Permiso ?", "question").then((r) => {
                if (r.value) {
                    _sPermissionsPersonByGroup.save(item, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.initialize();
                        }
                    });
                }        
                });
            },
            openEditHourEntry(item){                
                this.itemUpdate = item;
                this.showEntryHour = true;
            },
            closeDialog(){
                this.showEntryHour = false;
            },
            saveEntryHour()
            {
                this.$fun.alert("Esta seguro de guardar Hora de Retorno ?", "question").then((r) => {
                    if (r.value) {
                        this.itemUpdate.PpgEntryHour = this.PpgEntryHour;
                        this.itemUpdate.UsrUpdateID = this.$fun.getUserID(); // agrega el ID del usuario que modifico el registro
                        
                        _sPermissionsPersonByGroup
                        .save( this.itemUpdate, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Registrado correctamente", "success");                               
                                this.showEntryHour = false
                                this.initialize();
                            }
                        });
                    }
                });
            }
        },
        created(){
            this.initialize();
        }
    }
</script>