import Service from "../Service"

const resource = "senkglinesprocess/"


export default {

    save(obj, requestID){
        return Service.post(resource + "save", obj,{
            params: { requestID: requestID },
        });
    },

    list(obj, requestID){
        return Service.post(resource + "list", obj,{
            params: { requestID: requestID },
        });
    },

    update(obj, requestID){
        return Service.post(resource + "update", obj,{
            params: { requestID: requestID },
        });
    },


}