<template>
	<div>
        <s-toolbar dense dark color="#7b7b7b" close save @save="save()" @close="$emit('close')"> </s-toolbar>
            <v-container>
                <v-row>
                    <!-- CULTIVO -->
                    <v-col cols="4" lg="4" md="6" sm="12">
                        <s-select-definition
                            label="Cultivo"
                            v-model="itemHead.TypeCultive"
                            :def="1173"
                            @input="inputProdCategory($event)"
                        ></s-select-definition>
                    </v-col>
                    <!-- CATEGORIA -->
                    <v-col cols="3" lg="3" md="6" sm="12">	
                        <s-select
                            :items="ProductionCategory"
                            item-value="PdcID"
                            item-text="PdcDescription"
                            label="Categoria"
                            v-model="itemHead.PdcID"
                            :cultiveID="itemHead.TypeCultive"
                            @input="inputProdLines($event)"
                            ref="txtCategoria"
                        />							
                    </v-col>
                    <!-- LINEAS -->
                    <v-col cols="3" lg="3" md="6" sm="12">	
                        <s-select
                            :items="ProductionLines"
                            item-value="LncID"
                            item-text="LncDescription"
                            label="Linea"
                            v-model="itemHead.LncID"
                            :categoryID="itemHead.PdcID"
                            ref="txtLineas"
                        />							
                    </v-col>        
                    <!-- SALIDAS DE CATEGORIAS -->
                    <v-col cols="3" lg="3" md="6" sm="12">	
                        <s-select
                            :items="ProductionLines"
                            item-value="CecID"
                            item-text="CecDescription"
                            label="Salida de Categoria"
                            v-model="itemHead.CecID"
                            :categoryID="itemHead.PdcID"
                            ref="txtExitCategoria"
                        />							
                    </v-col>
                    
                    <v-col cols="4" lg="4" md="6" sm="12">
                        <s-select-definition
                            label="Estado"
                            v-model="itemHead.ClpState"
                            :def="1154"
                        ></s-select-definition>
                    </v-col>

                </v-row>
            </v-container>
	</div>
</template>


<script>
	import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js" //LINEAS EN PRODUCTION
    import _sFrzTypeExitCategoryService from "@/services/FrozenProduction/FrzTypeExitCategory.js" 
    

	export default {
		data() {
			return {
                ProductionCategory: [],
                ProductionLines:[],
                itemHead: {

                }
                
			}
		},

		methods: {
			
			save() {
                if(this.itemHead.PdcID == 0  || this.itemHead.PdcID == undefined)
                {
                    this.$fun.alert("Ingrese una categoria", "warning");
                    this.$refs.txtCategoria.focus();
                    return;
                }

                if(this.itemHead.LncID == 0  || this.itemHead.LncID == undefined)
                {
                    this.$fun.alert("Ingrese una Linea", "warning");
                    this.$refs.txtLineas.focus();
                    return;
                }
                if(this.itemHead.CecID == 0  || this.itemHead.CecID == undefined)
                {
                    this.$fun.alert("Ingrese una Linea", "warning");
                    this.$refs.txtExitCategoria.focus();
                    return;
                }
                


                this.$fun.alert("Esta seguro de guardar?", "question").then((r) => {
                if (r.value) {
                // if(this.isEdit){
                // }
                this.itemHead.UsrCreateID = this.$fun.getUserID();
                // this.itemHead.UsrUpdateID = this.$fun.getUserID();
                _sFrzCtnLinesProcessService
                    .save(this.itemHead, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.$emit("close");
                        }
                    });
                }
                });
			},

            inputProdCategory(TypeCultive){
                
                this.ProductionLines =[];
                
                _sFrzTypeExitCategoryService.list( { TypeCultive }, this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.ProductionCategory = r.data;     
                                   
                }
            });   
            },

            inputProdLines(PdcID){
                
                
                _sFrzCtnLinesProcessService.list( { PdcID }, this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.ProductionLines = r.data;                    
                }
            });   
            },


		},
        
	}
</script>