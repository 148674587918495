<template>
    <div>
        <v-card>
            <v-card-text>
                <v-row class="pt-1 pr-1 pl-1">
                    <v-col>
                        <h3> Lineas en Produccion</h3>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="3" lg="3" md="4" sm="12">
                        <s-select
                            label="Turno de Produccion"
                            :items="itemTurn"
                            item-value="TrnID"
                            item-text="TnrObservation"
                            v-model="processInitial.TrnID"
                            @input="initialize()"
                        >  
                        </s-select>
                    </v-col>
                    <!-- <v-col>
                        <s-select-definition
                            label="Turno"
                            :dgr="54"
                            :def="1062"
                            v-model="processInitial.TypeTurn"
                            @change="initialize()"
                        /> 
                    </v-col> -->
                    <v-col cols="4" lg="4" md="4" sm="12">
                        <s-select-definition
                            label="Cultivo" 
                            :def="1173"
                            v-model="processInitial.TypeCultive"
                            @change="initialize()"
                        ></s-select-definition>
                    </v-col>
                    <v-col cols="2" v-if="btnInitial">
                         <v-btn  
                            v-if="$fun.isAdmin() && Lines.length > 0"
                            
                            color="success"
                            @click="initialProcess()">
                            Iniciar Proceso
                        </v-btn> 
                    </v-col>
                    <v-col v-if="btnCut" hidden>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="warning"
                                    fab
                                    small
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="GenerateCutProcess()"
                                >
                                    <v-icon>mdi-content-cut</v-icon>
                                </v-btn>
                            </template>
                            <span>Corte</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <!-- <v-divider></v-divider> -->
                <v-row justify="center" v-if="Lines.length == 0 && itemsLineas.length == 0">
                   <p style="font-weight: bold;color: red;">Necesita crear Lineas para acondinionado</p>
                </v-row>
            </v-card-text>     
        </v-card>
        <br>
        <v-card>
            <v-card-text>
                <v-row class="pt-1 pr-3 pl-3">
                    <h3>LISTADO</h3>
                    <v-spacer></v-spacer>
                </v-row><br>
                <v-row class="pt-1 pr-3 pl-3">
                    <v-col v-if="btnRefresh">
                        <v-btn  
                            color="success"
                            @click="ClickRefreshLines()">
                            Actualizar Lineas
                        </v-btn> 
                    </v-col>
                </v-row>
                <!-- LISTADO DE LINEAS  -->
                <v-row justify="center" style="margin-top: 20px;">                    
                    <v-card dense>
                        <v-card-text v-if="itemsLineas.length > 0">
                            <v-row  style="margin: auto;">
                                <div
                                    
                                    class="grafLineMain"
                                    v-for="item in itemsLineas"
                                    :key="item.ClpID"
                                >
                                    <!-- lista de lineas -->
                                    <c-lines-produc 
                                    :line="item"
                                    @close="closeLines($event)"
                                    @refreshlines="refreshlines()"
                                    >

                                    </c-lines-produc>             
                                </div>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-if="Lines.length > 0">
                            <v-row justify="center" style="margin: 20px;">
                                <!-- <v-card>
                                    <v-card-text> -->
                                        <v-col cols="12" v-for="(linea, i) in Lines" :key="i">                                           
                                            <s-switch
                                                :label="linea.LncDescription ? linea.LncDescription : linea.LncDescription"                                            
                                                v-model="linea.SecStatus" color="primary"                                                
                                            ></s-switch>
                                        </v-col>
                                    <!-- </v-card-text>
                                </v-card> -->
                            </v-row>
                        </v-card-text>
                    </v-card>    
                    
                </v-row>
                <!-- FIN DE LISTADO DE LINEAS -->
            </v-card-text>     
        </v-card>


        <v-dialog
			persistent
			v-model="showLinesProcessDetail"
			transition="dialog-bottom-transition"
			width="80%"
			v-if="showLinesProcessDetail"
		>
			<v-card class="elevation-0">
				<showLinesProcessDetail
					@close="closeDialog()">
				</showLinesProcessDetail>
			</v-card>
		</v-dialog>

        <!-- GENERAR CORTE -->
        <v-dialog
            v-model="dialogGenerateCut"
            v-if="dialogGenerateCut"
            width="500"
        >
            <cut-process
                @CloseGenerateCutProcess="CloseGenerateCutProcess()"
                :tablesActiveTotal="tablesActiveTotal"
            ></cut-process>
        </v-dialog>
        
       
    </div>
</template>


<script>
    import _sTurnService from "../../../services/FreshProduction/PrfTurnService"; // SERVICIO DEL TURNO

    import showLinesProcessDetail from "./showLinesProcessDetail.vue";
    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"

    
    import cLinesProduc from "./CLinesProduc.vue"
    import CutProcess from './CutProcess.vue';

  
  export default {
    components: { showLinesProcessDetail, cLinesProduc, CutProcess },
    prop: {
      
    },
    data: () => ({
        itemTurn:[],
        showLinesProcessDetail: false,
        itemsLineas:[],
        // itemsTablesLines:[],
        modelDialog : false,
        processInitial: {
            // DateProcess: null,
            TrnID: null,
            // TypeTurn: null,
            TypeCultive: null
        },
        btnInitial: false,
        btnCut:false,
        dialogGenerateCut: false,
        tablesActiveTotal: [],
        btnRefresh:false,
        Lines: [],
        SelectLinea: [],
    }),
    watch: {
        processInitial(){
        }
    },
    methods: {
        GenerateCutProcess()
        {
            this.tablesActiveTotal = [];
            this.itemsLineas.forEach(element => {
                let ClpID = element.ClpID;
                _sFrzCtnLinesProcessService
                .listTablesByLines({ClpID},this.$fun.getUserID())
                .then(r => {
                    if(r.status == 200){
                        this.tablesActiveTotal.push(...r.data);
                    }
                })
            })
            this.dialogGenerateCut = true;
        },

        CloseGenerateCutProcess()
        {
            this.dialogGenerateCut = false;
            this.initialize();
        },

        refreshlines(){
            this.itemsLineas = [];
            this.initialize();
        },
        
        initialProcess(){
            this.$fun.alert("Esta seguro de Iniciar Proceso?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {

                    // if (this.processInitial.DateProcess == null || this.processInitial.TypeTurn == null || this.processInitial.TypeCultive == null) {
                    if (this.processInitial.TrnID == null || this.processInitial.TypeCultive == null) {
                        return;
                    }

                    if(this.Lines.length > 0) 
                    {
                        this.processInitial.ProductionLines = this.Lines;
                    }
                    this.processInitial.UsrCreateID = this.$fun.getUserID();
                    _sFrzCtnLinesProcessService.initial(this.processInitial, this.$fun.getUserID())
                    .then((r) => 
                    {                        
                        if (r.status == 200) 
                        {                           
                            this.initialize();
                        }
                    })
                }
            });
        },
        closeLines(line){
            this.initialize();
        },
        openDialog(item) {
            this.items = item;
            this.modelDialog = true;
        },
        closeDialog(){
            this.showLinesProcessDetail = false;
            this.modelDialog = false;
            this.initialize();
        },
        openLinesProcessDetail() {
            this.showLinesProcessDetail = true;
		},
        initialize(){
            this.itemsLineas = [];
            
            //if ( this.processInitial.DateProcess == null || this.processInitial.TypeTurn == null || this.processInitial.TypeCultive == null) {
            if ( this.processInitial.TrnID == null || this.processInitial.TypeCultive == null) {
                return;
            }
            
            let ntpId = this.$fun.isAdmin() ? null : this.$fun.getUserInfo().NtpID;

            this.processInitial.NtpID = ntpId; 
            _sFrzCtnLinesProcessService.listLineas(this.processInitial, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    this.itemsLineas = r.data
                    
                    if ( this.itemsLineas.length == 0 ) {
                        this.btnInitial = true;
                        this.btnCut = false;
                        this.btnRefresh = false;
                        this.getLineas();
                    }else
                    if ( this.itemsLineas.length > 0 ) {
                        this.Lines = [];
                        this.btnCut = true;
                        this.btnInitial = false;
                        this.btnRefresh = true;
                    }
                     
                }
            })
        },
        TurnActive(){
            let TypeArea = 4
            _sTurnService.turnActive({TypeArea : TypeArea}, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;           
                            
                    }
                });      
        },
        ClickRefreshLines(){
            
            this.initialize();

        },
        getLineas()
        {
            _sFrzCtnLinesProcessService.getLineasCreated(this.processInitial, this.$fun.getUserID()).then(r => {
                if(r.status == 200)
                {
                    this.Lines = r.data;
                }
            });
        }

      
    },
    created () {
			this.initialize();
            this.TurnActive();
            
	},


  };
  </script>
  