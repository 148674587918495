import Service from "../Service";
const resource = "FrzCtnMeasurementMinute/";

export default {

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(prd, requestID) {
        return Service.post(resource + "pagination", prd, {
            params: { requestID: requestID },
        });
    },
    Update(prd, requestID) {
        return Service.post(resource + "Update", prd, {
            params: { requestID: requestID },
        });
    },


}
