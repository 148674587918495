<template>
    <div :class="stationWork">
        <div v-if="dolls1" class="dollInTable"></div>
        <v-btn 
            fab
            x-small
            text
            @click="OpenDialogInfoTable(table)"
        >
            <v-icon class="mdi mdi-table-furniture"></v-icon>
        </v-btn>
        <v-menu v-if="groupPerson.PdgID "
            bottom
            left
        >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                x-small
                class="black--text"
                
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                
                v-for="(itemL, i) in itemsL"
                :key="i"
                @click="openTranfersGroup(itemL,i, groupPerson)"
              >
                <v-list-item-title>{{ itemL.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
        <br>
        <span>{{ table.CttName }}</span><br>
        
        <i v-if="!groupPerson.PdgID " class="fa fa-users" size="xl" aria-hidden="true" style="
         color: #09e172; cursor: pointer; border-style: solid; border-color:#09e172
         ; border-radius: 9px;border-width: 2px; padding: 3px;" 
            @click="clickAddGroups(table)">
        </i>
        
      
        <p v-else > 
            
            <!-- <v-chip x-small="" color="warning"  @click="showGroupPerson(groupPerson.PdgID)">
                {{ groupPerson.PdgNameGroup }}
            </v-chip> -->
            <v-badge overlap color="purple" small>
                <template v-slot:badge>
                    <span>{{ groupPerson.numberPerson}}</span>
                </template>

                <v-chip x-small="" color="warning"  @click="showGroupPerson(groupPerson.PdgID)">
                {{ groupPerson.PdgNameGroupCut }}
                </v-chip>
            </v-badge>


                <!-- MODAL DE TRANSFERENCIAS DE PERSONAS A OTROS GRUPOS,MESAS,LINEAS  -->
                <v-dialog
                    v-model="dialogTransferPerson"
                    persistent
                    max-width="900"
                    height="400px"
                    v-if="dialogTransferPerson">

                    <v-card>
                        <s-toolbar     
                            label="Transferir personal entre mesas Y asistencia"
                            dark
                            close
                            @close="closeDialogGroupPerson()"
                            color="primary">
                        </s-toolbar>

                        <v-card-actions>
                           <v-row>
                                <v-col cols="5" lg="5" md="5" sm="12">
                                   <v-btn
                                        v-if="this.lineprocess.TypeCultive == 1"
                                        x-small
                                        color="success"
                                        @click="addMangosByHours()"
                                        >
                                        MANGOS POR HORA
                                    </v-btn>  
                                </v-col>
                            </v-row> 
                        </v-card-actions>
                        <v-card-title>
                            
                            <v-row>
                                <v-col cols="8" lg="8" md="8" sm="12">
                                    <h3>{{ table.CttName }} - {{ groupPerson.PdgNameGroup }}</h3>
                                </v-col>
                                <!-- GREGAR LA CANTIDAD DE MANGOS POR HORA POR PND Y VONVERTIR A JABAS -->
                                <v-spacer></v-spacer>
                                <v-btn
                                    x-small
                                    color="primary"
                                    @click="ModalTransferSelectedPerson = true"
                                >
                                Transferir
                                </v-btn>                          
                                &nbsp;&nbsp;
                              
                                <v-btn
                                    x-small
                                    color="warning"
                                    @click="SaveAssistance()"
                                    v-if="showbuton"
                                >
                                Asistencia
                                <!-- v-if="showbuton" -->
                                </v-btn>
                            </v-row>

                        </v-card-title>

                        <!-- integrantes por grupo -->
                        <v-card-text>
                            <v-data-table
                                v-model="selected"
                                :headers="headerProductionGroup"
                                :items="itemsDataPG"
                                :items-per-page="-1"
                                item-key="PgdID"
                                show-select
                                class="elevation-1"
                                disable-sort
                                hide-default-footer
                                dense
                                @item-selected="rowSelected($event)"
                                @input="enterSelect($event)"
                            >
                                <template v-slot:item.LpmHourInitial="{ item }"> 
                                    <!-- <div v-if="inputHour">
                                        <v-icon small color="warning">fa-clock</v-icon>
                                        {{item.LpmHourInitial}} 
                                    </div>    v-if="inputHour1"  -->
                                    <div class="relojito" >
                                        <datetime valueZone="America/Lima"  type="time" v-model="item.LpmHourInitial">
                                        </datetime>
                                    </div>
                                </template>

                                <template v-slot:item.TypeRoll="{ item }"> 
                                    <!-- <s-select-definition
                                        :def="1430"
                                        v-model="item.TypeRoll"
                                        clearable
                                    ></s-select-definition> -->
                                    <s-select
                                        :items="ItemsRoll"
                                        item-value="CrlID"
                                        item-text="CrlNameRol"
                                        v-model="item.TypeRoll"
                                        clearable
                                    >

                                    </s-select>
                                </template>

                                <template v-slot:item.saveRoll="{ item }">
                                    <v-btn
                                        color="success"
                                        dark
                                        :disabled="false"
                                        icon
                                        small
                                        @click="SaveRoll(item)"
                                    >
                                        <v-icon>mdi-send</v-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:item.medirP="{ item }">
                                    <v-btn
                                        color="info"
                                        dark
                                        icon
                                        small
                                        v-if="item.TypeRoll == 3"
                                        @click="MeasurementByMinute(item)"
                                    >
                                        <v-icon>mdi-alarm-check</v-icon>
                                    </v-btn>
                                </template>


                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!-- FIN DE MODAL DE TRANSFERENCIAS DE PERSONAS A OTROS GRUPOS,MESAS,LINEAS -->
        </p> 
        
        <!-- MODAL DE GRUPO DE PERSONAS  -->
        <v-dialog
            v-model="modelDialog"
            persistent
            max-width="900"
            height="400px"
            v-if="modelDialog">
            <v-card>
                <s-toolbar     
                    label="Asignar grupos a las mesas"
                    dark
                    close
                    @close="closeDialog()"
                    save 
                    @save="save()"
                    color="primary">
                </s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                        Estas en la <h1>{{ table.CttName }}  </h1> <!-- {{ table.PdcDescription}}-->
                        </v-col>
                    </v-row>
                    <!-- componente de grupo de personas -->
                    <v-col cols="12" lg="12" md="12" sm="12">
                        <s-select-group-tables 
                            :processInitial="lineprocess"
                            @getPdgID="getPdgID($event)" 
                            @HourInitial="HourInitial($event)"
                            
                        />
                        <!-- ref="txtGroupsTables" -->
                    </v-col>

                    <!-- integrantes por grupo -->
                    <v-col class="pl-0 pt-0">
                        <v-data-table
                            dense
                            :headers="headerProductionGroupD"
                            :items="itemsDataPG"
                            hide-default-footer
                            :items-per-page="-1"
                            disable-sort
                        >
                        
                        </v-data-table>
                    </v-col>

                    <v-col cols="4" lg="4" md="6" sm="12">
                        <s-text
                            hidden
                            label="Mesa"
                            v-model="table.CttID"
                        ></s-text>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- FIN DE MODAL DE GRUPO DE PERSONAS -->  

        <!-- MODAL PARA LA INFORMACIÓN DE LA MESA -->
        <v-dialog
            v-if="dialogInfoTable"
            v-model="dialogInfoTable"
            persistent
            width="500"
        > <!-- :table="table"
                 -->
            <info-table
                :CttID="table.CttID"
                :filter="lineprocess"
                :dolls="dolls"
                :CttName="table.CttName"
                :mDlpCodBase="mDlpCodBase = true"
                @CloseDialogInfoTable="CloseDialogInfoTable()"
            ></info-table>
        </v-dialog>

        <!-- MODAL PARA TRANFERIR PERSONAL SELECCIONADO A OTRAS MESAS -->
        <v-dialog
        persistent
        v-model="ModalTransferSelectedPerson"
        v-if="ModalTransferSelectedPerson && selected.length > 0"      
        >
            <v-card>
                <v-row>
                    <v-col>
                    <s-toolbar  
                        color="primary"                  
                        dark
                        close
                        @close="ModalTransferSelectedPerson = false"
                        label="Transferencia"
                    >
                    </s-toolbar>
                    </v-col>
                </v-row>
                <v-container>
                    <v-row>
                        <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">
                            <label  aria-hidden="true" class="v-label theme--light"><b>Hora Transferencia</b></label>
                            <div class="relojito">
                            <datetime valueZone="America/Lima"  type="time" v-model="LpmHourTransferPerson">
                            </datetime>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="9" lg="9" md="9" sm="12">
                            <tables-Lines
                            :filter="lineprocess"
                            @emitTable="emitTable($event)"
                            >

                            </tables-Lines>
                        </v-col>   
                    </v-row>
                    <v-row>
                        <v-col cols="4" lg="4" md="6" sm="12">
                        <v-btn
                            small
                            elevation="0"                            
                            dark
                            color="primary"
                            @click="saveTransferPersonSelect"
                        >
                            Cambiar
                        </v-btn>                         
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <!-- MODAL TRANSFERENCIAS DE GRUPOS -->

        <v-dialog
            v-if="TransferGroup"
            v-model="TransferGroup"
            persistent
            width="800"
        > 
            <v-card>
                
                <s-toolbar  
                    color="primary"                  
                    dark
                    close
                    @close="TransferGroup = false"
                    label="Transferencia de Grupos"
                >
                </s-toolbar>
                   
                <v-container>
                    <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12" class="s-col-form">
                            <h3> Transferir - {{ groupPerson.PdgNameGroup }} a  : </h3> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">
                            <label  aria-hidden="true" class="v-label theme--light"><b>Hora Fin</b></label>
                            <div class="relojito">
                            <datetime valueZone="America/Lima"  type="time" v-model="DlpHourEndEstation">
                            </datetime>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12">
                            <tables-Lines
                            :filter="lineprocess"
                            @emitTable="emitTable($event)"
                            >
                            </tables-Lines>
                        </v-col>   
                    </v-row>
                    <v-row>
                        <v-col cols="4" lg="4" md="6" sm="12">
                        <v-btn
                            small
                            elevation="0"                            
                            dark
                            color="primary"
                            @click="saveTransferGroup"
                        >
                            Cambiar
                        </v-btn>                         
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <!-- MODAL PARA Observaciones -->

        <v-dialog
            v-if="ModalObsGroup"
            v-model="ModalObsGroup"
            persistent
            width="500"
        > 
            <v-card>
                <v-row>
                    <v-col>
                        <s-toolbar  
                            color="primary"                  
                            dark
                            close
                            @close="ModalObsGroup = false"
                            label="Observaciones"
                        >
                        </s-toolbar>
                    </v-col>
                </v-row>
                <v-container>
                    <v-tabs v-model="currentItem">
                        <v-tab href="#Observations"> Observaciones</v-tab>
                        <v-tab href="#ListObservations"> Listado de Observaciones</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="currentItem">
                        <v-tab-item :value="'Observations'"> 
                            <v-row>
                                <v-col cols="12" lg="12" md="6" sm="12">
                                    <h4> {{ groupPerson.PdgNameGroup }}: </h4> 
                                </v-col>   
                            </v-row>
                            <v-row>
                                <v-col cols="12" lg="12" md="12" >
                                    <s-textarea 
                                
                                    label="Detalle sus Observaciones"
                                    v-model="itemHead.CotObservation"
                                    >
                                    </s-textarea>
                                </v-col>   
                            </v-row>
                            <v-row>
                                <v-col cols="4" lg="4" md="6" sm="12">
                                    <v-btn
                                        small
                                        elevation="0"                            
                                        dark
                                        color="primary"
                                        @click="saveObsGroup"
                                    >
                                        Guardar
                                    </v-btn>                         
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item :value="'ListObservations'"> 
                            <!-- LISTADO DE Observaciones -->
                            <v-card-text class="py-0">
                                <v-timeline
                                align-top
                                dense
                                >
                                    <v-timeline-item
                                        v-for="(itemTL, index) in listObs"
						                :key="index"
                                        color="pink"
                                        small
                                    >
                                        <v-row class="pt-1">
                                        <v-col cols="3">
                                            <strong>{{itemTL.CotObservationHourN}}</strong>
                                        </v-col>
                                        <v-col>
                                            <strong>Observación</strong>
                                            <div class="text-caption">
                                                {{itemTL.CotObservation}}
                                            </div>
                                        </v-col>
                                        </v-row>
                                    </v-timeline-item>                                       
                                </v-timeline>
                            </v-card-text>
                        </v-tab-item>
                    </v-tabs-items>
                </v-container>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="modalPermissionsPerson"
            v-model="modalPermissionsPerson"
            persistent 
            width="1200">
            <permissions-person @modalPermissionsPerson="modalPermissionsPerson = false"
                :itemdetaillineprocess="itemdetaillineprocess">

            </permissions-person>
        </v-dialog>

        <!-- Modal para pausa en mesa -->
        <v-dialog
            v-if="modalStockTable"
            v-model="modalStockTable"
            persistent 
            width="500">
            <stock-table @refreshlines="refreshlines()" @modalStockTable="modalStockTable = false" :itemTable="itemTable">
            </stock-table>
        </v-dialog>

        <!-- MODAL PARA INGRESAR CANTIDAD DE MANGOS POR PND -->
        <v-dialog
            v-if="modalMangoHours"
            v-model="modalMangoHours"
            persistent 
            width="1200"
        >
            <v-card>
                <s-toolbar  
                    color="primary"                  
                    dark
                    close
                    @close="modalMangoHours = false"
                    label="Mangos por horas"
                >
                </s-toolbar>
                <v-card-text>
                    <c-add-mangos-hours
                        :ClpID = table.ClpID
                        :CttID = table.CttID
                        :TrnID = lineprocess.TrnID
                        :TypeCultive = lineprocess.TypeCultive
                        :PdgID = table.PdgID
                    >
                    </c-add-mangos-hours>
                </v-card-text>
            </v-card> 
        </v-dialog>

        <!-- MODAL DE MEDICIONES POR MINUTO DE SACA POLOS -->
        <v-dialog
            v-if="modalMediciones"
            v-model="modalMediciones"
            persistent 
            width="900"
        >
            <v-card>
                <s-toolbar  
                    color="primary"                  
                    dark
                    close
                    @close="modalMediciones = false"
                    label="Mediciones Por Minuto"
                >
                </s-toolbar>
                <v-card-text>
                    <c-measurement-minute
                        :ClpID = table.ClpID
                        :CttID = table.CttID
                        :TrnID = lineprocess.TrnID
                        :TypeCultive = lineprocess.TypeCultive
                        :PdgID = table.PdgID
                        :PgdID = table.PgdID
                        :TypeRoll = table.TypeRoll
                        :PrsID = table.PrsID
                    >
                    </c-measurement-minute>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

  
<script>

    import _dollTableService from "@/services/FrozenProduction/FrzDollTableService.js"
    import _sFrzCtnDetailLinesProcess from "@/services/FrozenProduction/FrzCtnDetailLinesProcess.js";
    import _sProductionGroupService from "@/services/FrozenProduction/FrzProductionGroupService.js"; // grupos de trabajo - Asignar rol
    import InfoTable from './InfoTable.vue';
    import TablesLines from '../../../components/FrozenProduction/Aconditionado/TablesLine.vue';
    import PermissionsPerson from "./FrzCtnPermissionsinProcess.vue"
    import StockTable from "./StockTable.vue"
    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"; // en este caso para hacer tranferencia de trabajadores entre grupos
    import _sConditioningRoleService from "@/services/FrozenProduction/FrzConditioningRoleService.js"

    import _sStop from "@/services/FrozenProduction/FrzCtnLinesProcessStop.js"

    import cAddMangosHours from "./CAddMangosHours.vue";
    import cMeasurementMinute from "./CMeasurementMinute.vue";
  
    export default {
    // component:{sSelectGroupTables},
    components: {
        PermissionsPerson,
        sSelectGroupTables: () => import('./sSelectGroupTables.vue'),
        InfoTable: () => import('./InfoTable.vue'),
        TablesLines:()=> import ('../../../components/FrozenProduction/Aconditionado/TablesLine.vue'),
        StockTable,
        cAddMangosHours,
        cMeasurementMinute,

    },
    props: {
        
        lineprocess: {
            type: Object,
            required: false,
        },
        table: {
            type: Object,
            required: true,
        },
       
        
    },
    data: () => ({
        item:{},
        currentItem: "tab-Funciones",
        itemHead: {},
        selected: [],
        ModalTransferSelectedPerson: false,
        modalPermissionsPerson: false,
        modalStockTable: false,
        TransferGroup : false,
        ModalObsGroup : false,
        dialogInfoTable : false,
        dialogTransferPerson:false,
        show: false,
        itemsTablesLines:[],
        modelDialog : false,
        groupPerson: {
            PdgID: 0,
        },
        itemsDataPG:[],
        headerProductionGroup: "",
        headerProductionGroup: [
            { text:"item", value :"Line"},
            { text: "Nombre", value: "PgdName"},
            { text: "Apellidos", value: "PgdLastName"},
            { text: "DNI", value: "PrsDocumentNumber"},
            { text: "Hora de ingreso ", value: "LpmHourInitial", width: 200},
            { text: "Descripcion Rol", value: "TypeRoll", width: 200 },
            { text: "Rol", value: "saveRoll" },
            { text: "Mediciones", value: "medirP" },

        ],
        headerProductionGroupD: "",
        headerProductionGroupD: [
            { text:"item", value :"Line"},
            { text: "Nombre", value: "PgdName"},
            { text: "Apellidos", value: "PgdLastName"},
            { text: "DNI", value: "PrsDocumentNumber"},
        ],
        dolls: [], 
        dolls1 :0,
        PdgID: 0,
        itemsL:"",
        itemsL: [
            { title: 'Transferir Grupos',value:"transferGroups" },
            { title: 'Observaciones',value:"ObsGroups"  },
            { title: 'Permisos de Trabajadores',value:"UrlPrmsPerson"  },            
        ],
        isload: false,
        TypeRoll: 0,
        medirP: 0,
        LpmHourInitial: null,
        listObs: [],
		showbuton: false,
        selectedPersonGroup: [],
        itemTable:{},
        stationWork: '',
        modalMangoHours: false,
        modalMediciones: false,
        DlpHourInitialEstation:"",
        DlpHourEndEstation:"",
        mDlpCodBase:false,
        ItemsRoll:[],

    }),

    watch: {
        // selected(newValue, oldValue)
        // {
        
        //     this.showbuton = false;

        //     let countHour = 0;
        //     newValue.forEach(element => {
        //         if(element.LpmHourInitial != null)
        //         {
        //             countHour ++;
        //         }
        //     });
        //     if(countHour > 0 || newValue.length == 0  || newValue == "")
        //     {
        //         this.showbuton = false;
        //     }
        //     else{
        //         this.showbuton = true;
        //     }
        // }
    },

    
    methods: {
        loadRoll(){
            _sConditioningRoleService.List({TypeCultive : this.lineprocess.TypeCultive}, this.$fun.getUserID()).then(r =>{
                if(r.status == 200)
                {
                    this.ItemsRoll = r.data; 
                                
                }
            });
        },
        SaveRoll(item){

            this.TypeRoll = item.TypeRoll;
                

                let items = {
                    PgdID : item.PgdID,
                    TypeRoll : item.TypeRoll,
                    ClpID : this.lineprocess.ClpID,
                    UsrCreateID : this.$fun.getUserID(),
                    UsrUpdateID : this.$fun.getUserID(),
                    TrnID       : this.lineprocess.TrnID,
                }
                
                // return

                _sProductionGroupService.assignRoll(items, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                        this.$fun.alert("Los Datos de Guardaron correctamente", "success");
                        this.getPdgID(item.PdgID);
                        this.dialogTransferPerson = false;
                    }
                });
    
        },
        emitTable( table ){
            
            this.PgdID = table.PgdID
            this.PdgID = table.PdgID
            this.ClpID = table.ClpID
            this.CttID = table.CttID
            this.TypeRoll = table.TypeRoll
     
        },
        
        OpenDialogInfoTable(table)
        { 
            
            let filter = {
                CttID : this.table.CttID,
                ClpID : this.lineprocess.ClpID,
                DtbStatus : 1
            }
            _dollTableService
            .list(filter, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.dolls = resp.data;
                        this.dialogInfoTable = true;
                        
                    }
                })
          
        },

        mountedInfTable(table)
        {
            
            let filter = {
                CttID : this.table.CttID,
                ClpID : this.lineprocess.ClpID,
                DtbStatus : 1
            }
            _dollTableService
            .list(filter, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.dolls1 = resp.data[0].DtbID;
                        
                    }
                })
          
        },
       
        CloseDialogInfoTable()
        {
            this.dialogInfoTable = false;
        },

        closeDialogGroupPerson()
        {
            this.dialogTransferPerson = false;
        },

        clickAddGroups(item) {
            this.items = item;
            this.modelDialog = true;
        },
        closeDialog(){
            this.modelDialog = false;
        },
        save(){
            
            // if(this.table.TypeTurn == undefined ){
            //     this.$fun.alert("Seleccione turno","warning");
            //     return
            // }
            if(this.table.PdgID == 0  || this.table.PdgID == undefined)
            {
                this.$fun.alert("Seleccione un Grupo de Produccion", "warning");
                return;
            }

            this.$fun.alert("Esta seguro de guardar?", "question").then((r) => {
                if (r.value) {
           
                    let obj = {
                        PdgID       : this.table.PdgID,
                        ClpID       : this.lineprocess.ClpID,
                        CttID       : this.table.CttID,
                        DlpState    : this.table.DlpState,
                        UsrCreateID : this.$fun.getUserID(),
                        UsrUpdateID : this.$fun.getUserID(),
                        DlpHourInitialEstation : this.DlpHourInitialEstation,
                        TrnID       : this.lineprocess.TrnID,
                        
                    }
                // this.table.UsrCreateID = this.$fun.getUserID();
                // this.table.UsrUpdateID = this.$fun.getUserID(); // agrega el ID del usuario que modifico el registro
                // this.table.ClpID       = this.lineprocess.ClpID;

                
                

                // return
                _sFrzCtnDetailLinesProcess
                    .save(obj, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            // this.$emit("close");
                            this.modelDialog = false; //verificar
                            this.getGroupPerson(this.table.CttID)
                                                        
                            
                        }
                    });
                }
            });
        }
        ,
        getPdgID(pdgID){
            this.table.PdgID = pdgID;
            
 
            _sProductionGroupService.listdetail({ pdgID }, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {

                        r.data.forEach((el, index) => {
                            el.Line = index + 1;
                        });
                        

                        this.itemsDataPG = r.data;
                    }
                   
                })
        },
        HourInitial(DlpHourInitialEstation){
            
            this.DlpHourInitialEstation = DlpHourInitialEstation;
        },
        getGroupPerson(CttID){
            //se debe revisar
            
             _sFrzCtnDetailLinesProcess
                    .list({ CttID, ClpID: this.lineprocess.ClpID },this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if (r.data) {
                                
                                this.groupPerson = r.data; 
                                // this.getGroupPerson(this.table.CttID);
                                
                            }                                  
                        }
                    });
               
        },
        showGroupPerson(pdgID){
            
            
            this.dialogTransferPerson = true;
            this.table.PdgID = pdgID;

            let obj ={
                PdgID : this.table.PdgID,
                //DateProcess : this.lineprocess.DateProcess,
                TrnID : this.lineprocess.TrnID,
                //TypeTurn : this.lineprocess.TypeTurn,
                TypeCultive :this.lineprocess.TypeCultive
            }
          
            
            _sProductionGroupService.listdetail(obj , this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {

                        r.data.forEach((el, index) => {
                            el.Line = index + 1;
                        });
                        

                        this.itemsDataPG = r.data;
                    }
                   
                });
        },
        saveTransferPersonSelect(){
            // let PdgIDBefore = this.PdgID
            

            this.$fun.alert("¿Desea Transferir personal a otro Grupo?", "question")
            .then((r) => {

                if (r.value) {
                    
                    let list = [];
                    this.selected.forEach(element => {
                        list.push(element.PgdID);
                    });
                    let items = {};
                    items.List = list;
                    
                    items.PdgIDOrigin = this.table.PdgID;
                    items.PdgIDDestiny = this.PdgID;
                    items.UsrCreateID = this.$fun.getUserID();
                    items.UsrUpdateID = this.$fun.getUserID();
                    items.ClpID = this.ClpID;
                    items.LpmHourTransferPerson = this.LpmHourTransferPerson;
                    items.TrnID = this.lineprocess.TrnID;

                    
                    // return
                    
                    
                    
                    
                    if(!items.PdgIDOrigin) {
                        this.$fun.alert("Estacion sin grupo asignado", "warning");
                        return;
                    }else{

                        this.selected = [];
                        _sFrzCtnLinesProcessService.saveTransfer(items, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                            this.$fun.alert("Los Datos de Guardaron correctamente", "success");
                            this.ModalTransferSelectedPerson = false;
                            this.dialogTransferPerson = false;
                            this.getGroupPerson(this.table.CttID);
                            this.$emit("refreshlines"); 
                            }
                        })
                    }
                }
            });
        },
        openTranfersGroup(item,i, detaillineprocess){           
            if(i == 0){
                this.TransferGroup = true   
            }
            if(i == 1){
                this.ModalObsGroup = true   
            }
            if(i == 2){
                this.clickUrlPermissions(detaillineprocess);
            }
            if(i == 3){
                
                this.clickStockTable(item, detaillineprocess);
            }

        },
        saveTransferGroup(){
            

            if(this.PdgID > 0) {
                this.$fun.alert("Estacion con grupo asignado", "warning");
                return;
            }else{

                this.$fun.alert("Desea Transferir Grupo?", "question").then((r) => {
                    if (r.value) {
                        let items = {};
                        // items.PdgID = this.PdgID;
                        items.DlpID = this.groupPerson.DlpID
                        items.PdgID = this.table.PdgID
                        items.CttID = this.CttID
                        items.ClpID = this.ClpID
                        items.UsrCreateID = this.$fun.getUserID();
                        items.UsrUpdateID = this.$fun.getUserID(); 
                        items.DlpHourEndEstation = this.DlpHourEndEstation;
                        items.TrnID = this.lineprocess.TrnID;
                        
                        // return
                    
                        _sFrzCtnDetailLinesProcess.transferGroup(items, this.$fun.getUserID())
                            .then((r) => {
                                if (r.status == 200) {
                                this.$fun.alert("Los Datos de Guardaron correctamente", "success");
                                // this.$emit("close");
                                this.TransferGroup = false
                                this.$emit("refreshlines");
                                
                            }
                        });
                    }
                });
            }
        },
        SaveAssistance(){
            
            
            // this.PgdID = this.item.PgdID;
            if(!this.selected[0].LpmHourInitial){
                this.$fun.alert("Ingrese Hora de asistencia", "warning");
                return;
            }
            if(this.selected.length <= 0){
                this.$fun.alert("Seleccione personal", "warning");
                return;
            }else{
               


                this.$fun.alert("¿Desea Guardar Asistencia?", "question")
                .then((r) => {

                    if (r.value) {
                        
                        let list = [];
                        this.selected.forEach(element => {
                            list.push(element.PgdID);
                        });
                            // let items = {};
                            // items.List = list;
                        let obj = {
                            List : list,
                            // List : this.selected,
                            PdgID : this.table.PdgID,
                            UsrCreateID : this.$fun.getUserID(),
                            UsrUpdateID : this.$fun.getUserID(),
                            ClpID       : this.lineprocess.ClpID,
                            LpmHourInitial : this.selected[0].LpmHourInitial,
                        }
                       
                        
                        
                        // return
                        
                        
                       

                        this.selected = [];
                        _sFrzCtnLinesProcessService.saveAssistance(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Asistencia Guardada correctamente", "success");
                                this.ModalTransferSelectedPerson = false;
                                this.dialogTransferPerson = false;
                                this.$emit("refreshlines");
                            }
                        })
                        
                    }
                });
            }
        },
        saveObsGroup(){
            
            this.$fun.alert("¿Desea Guardar Observación?", "question")
                .then((r) => {
                    if (r.value) {
                        let obj = {
                            CttID : this.table.CttID,
                            PdgID : this.table.PdgID,
                            ClpID : this.table.ClpID,
                            CotObservation : this.itemHead.CotObservation,
                            UsrCreateID : this.$fun.getUserID(),
                        }

                        _sFrzCtnLinesProcessService.saveObsGroup(obj, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Observación guardado correctamente", "success");
                                this.ModalObsGroup = false;
                                this.LstObsGroup(this.table.CttID);
                            }
                        })
                        
                    }
                });
        },
        LstObsGroup(CttID){
            
            let obj ={
                CttID :  this.table.CttID,
                // DateProcess : this.lineprocess.DateProcess,
                // TypeTurn : this.lineprocess.TypeTurn,
                TrnID : this.lineprocess.TrnID,
                TypeCultive :this.lineprocess.TypeCultive
            }

            _sFrzCtnLinesProcessService.listObsGroup(obj, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    if (r.status == 200) {
                    this.listObs = r.data;
                    }
                }
            });
        },
        clickUrlPermissions (detaillineprocess) {
             // window.location.href = '/produccioncongelados/PermissionsPersonByGroup';             
            this.itemdetaillineprocess = detaillineprocess;
            this.modalPermissionsPerson = true;

        },
        clickStockTable(item, detaillineprocess){

            if(item.value == "tablestock") {  
                this.itemTable = detaillineprocess;
                this.modalStockTable = true;
            }else{
                this.itemTable = detaillineprocess;
                
                this.startTable();
            }
        },
        rowSelected(item) {
        

        // let iihih = this.selected.filter( x => x.LpmHourInitial != null )
        
        // if(iihih.length > 0){
        //     this.inputHour = true;
        //     this.inputHour1 = false;
            
        // } else{
        //     this.inputHour1 = true;
        //     this.inputHour = false;

        // }
        },
        enterSelect(item){
            
            if(item.length > 0){
                this.showbuton = true
            }  else {
                this.showbuton = false
            }
            
        },
        refreshlines()
        {
            this.$emit("refreshlines");
        },
        startTable()
		{
            this.$fun.alert("¿Seguro de reanudar?", "question")
            .then(r =>{
                if(r.value)
                {
                    let stop = {};
                    stop.LpsID = 0;
                    stop.ClpID = this.itemTable.ClpID;
                    stop.CttID = this.itemTable.CttID;
                    stop.TypeAction = 2;
                    stop.TypeCategory = null;
                    stop.LpsResponsible = null;
                    stop.TypePlace = 2;//cuando se hace desde mesas
                    stop.SecStatus = 1;
                    stop.UsrCreateID = this.$fun.getUserID();
                    stop.UsrUpdateID = this.$fun.getUserID();

                    _sStop
                    .save(stop, this.$fun.getUserID())
                    .then(resp => {
                        if(resp.status == 200)
                        {
                            this.$fun.alert("Reanudado correctamente", "success");
                            
                            this.refreshlines()
                            return;
                        }
                    })
                }
            })
		},
        addMangosByHours(){
            
            this.modalMangoHours = true;
        },
        MeasurementByMinute(item){
           
            this.table.PgdID = item.PgdID;
            
            this.table.TypeRoll = item.TypeRoll;
            
            this.table.PrsID = item.PrsID
            
            
            if(item.TypeRoll != 3 ){
                this.$fun.alert("Seleccione trabajador con Rol Permitido", "warning");
                return;
            }
            
            
            this.modalMediciones = true;
        }

        
    },
    created () {
        // this.inputHour= true;
        if(this.table.DlpState == 2 ){
            this.stationWork = "stationStock";
            let item = {}
            item.title = 'Habilitar Mesa';
            item.value = "tablestart"           
            this.itemsL.push(item);
        }else{            
            this.stationWork = "stationWork";
            let item = {}
            item.title = 'Parar Mesa';
            item.value = "tablestock"
            this.itemsL.push(item);
        }     

        
        this.loadRoll()

        
    },
    mounted(){       

        this.getGroupPerson(this.table.CttID);
        this.LstObsGroup(this.table.CttID);
        this.mountedInfTable(this.table.CttID)


        this.LpmHourInitial = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
        this.DlpHourEndEstation = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
        this.LpmHourTransferPerson = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
    }

    };
  </script>

<style>
  .relojito {
      width:auto;
      height: auto;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(160, 159, 159);
      border-radius: 4px;
      padding: 3px;
    }
</style>