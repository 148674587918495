<template>
	<div>
		<v-card>
			
				<s-toolbar  rounded="0" color="primary" close @close="CloseGenerateCutProcess()"
					dark
					prominent>Generar Corte 
				</s-toolbar>
			<v-card-text>
				<v-row>
					<v-btn style="margin-left: 10px; margin-top: 0px;border: none;
  						background-color: transparent; 
  						cursor: pointer;
  						color: inherit;"      	
						@click="ResetLpcQuantityJaba()"		       			
        			>Establecer a 0 numero de Jabas
					</v-btn>
				
				</v-row>
				<v-row justify="center">					
					<v-col class="d-flex justify-content-center" >		
						<div class="containerLine">
							<div class="lineSide">
								<div v-for="(table, index) in itemsTablesOne" :key="index" > 
									<div 
										class="stationWorkCpntFree" 
										small
									>
										{{ table.CttName}}                            
										
										<s-text
											
											style="background-color: white; width: 65px; margin: 0px 7px;"
											v-model="table.LpcQuantityJaba"
											number
											:min="0"
										></s-text>
										<!-- <s-text
											v-if="table.LpcQuantityJaba"
											v-else 
											hidden
										></s-text> -->
									</div>
									
								</div>
							</div>
							<div class="grafLine" >
								
								<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
								
							</div>
							<div class="lineSide" >
								<div v-for="(table, index) in itemsTablesTwo" :key="index"> 
									
									<div 
										class="stationWorkCpntFree" 
										small
									>
										{{ table.CttName}}                            
										
										<s-text
											
											style="background-color: white; width: 65px; margin: 0px 7px;"
											v-model="table.LpcQuantityJaba"
											number
											:min="0"
										></s-text>
										<!-- <s-text
										 v-if=" table.DtbID"
											v-else 
											hidden
										></s-text> -->
									</div>

								</div>
								
							</div>
						</div>				
					</v-col>
					<v-col>
						<label slot="before" aria-hidden="true" class="v-label theme--light"><b>Hora Cierre Periodo</b></label>
                    		<div class="relojito">
								<datetime
									valueZone="America/Lima"  type="time"
									v-model="PprHour"
									label="Hora">
								</datetime>
							</div>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn
					
					color="success"
					text
					@click="SaveCutProcess()"
				>
					Guardar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="orange"
					text
					@click="CloseGenerateCutProcess()"
				>
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	import('../../../styles/linesPacking.css');
	import _scutService from "@/services/FrozenProduction/FrzCtnLinesProcessCutService.js"
	import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"
	import _dollTableService from "@/services/FrozenProduction/FrzDollTableService.js"

	export default {
		props: {
			// tablesActiveTotal: {
			// 	type: Number,
			// 	required: false
			// },
			ClpID: {
				type: Number,
				required: false
			},
		},

		data() {
			return {
				listTables: [],
				itemsTablesOne:[],
        		itemsTablesTwo:[],
				tables: [],
				dolls: [],
				PprHour: 0,
			}
		},

		methods: {
			CloseGenerateCutProcess() {
				this.$emit("CloseGenerateCutProcess");
			},
            refreshConf() {
                this.$emit("refreshConf");
			},
			ResetLpcQuantityJaba(){
				this.itemsTablesOne.forEach(element => {
					element.LpcQuantityJaba = 0;  					
				});

				this.itemsTablesTwo.forEach(element => {
					element.LpcQuantityJaba = 0; 					
				});
			},
			SaveCutProcess()
			{
				let confirmar = true;
				this.itemsTablesOne.forEach(element => {
					if(element.LpcQuantityJaba > 0)
					{
						confirmar = false;
						return;
					}
				});

				
				this.itemsTablesTwo.forEach(element => {
					if(element.LpcQuantityJaba > 0)
					{
						confirmar = false;
						return;
					}
				});
				
				if(!confirmar)
				{
					this.$fun.alert("¿Hay jabas que se quedaran sin procesar, desea continuar?", "question")
					.then(r => {
						if(r.value)
						{
							this.tables.forEach(element => {
								element.UsrCreateID = this.$fun.getUserID();
							});

							
							_scutService
							.save(this.tables, this.$fun.getUserID())
							.then((r) => {
								if (r.status == 200) {
									this.$fun.alert("Corte realizado correctamente", "success");
									this.CloseGenerateCutProcess();
									this.refreshConf();
								}
							});
						}
					})
				}else{

					this.$fun.alert("¿Seguro de realizar corte?", "question")
					.then(r => {
						if(r.value)
						{
							
							this.tables.forEach(element => {
								element.UsrCreateID = this.$fun.getUserID();
								element.PchDateEnd = this.PprHour;
							});

							
							_scutService
							.save(this.tables, this.$fun.getUserID())
							.then((r) => {
								if (r.status == 200) {
									this.$fun.alert("Corte realizado correctamente", "success");
									this.CloseGenerateCutProcess();
									this.refreshConf();
								}
							});
						}
					})
				}
			},

			GetTableByLine()
			{				
				_sFrzCtnLinesProcessService
				.listTablesByLines(
					{ClpID : this.ClpID}
				, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){
						this.tables = resp.data;

						this.itemsTablesOne = this.tables.filter(x => x.CttPosition == 1)
                    	this.itemsTablesTwo = this.tables.filter(x => x.CttPosition == 2)
						
						
						let x = 0;
						
						this.tables.forEach(element =>{
							if (element.DtbID > 0){
								x = element.CttID
							}
						})
						
						let filter = {
							CttID : x,
							ClpID : this.ClpID,
							DtbStatus : 1
						}
						
						_dollTableService
						.list(filter, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.dolls = resp.data;
								
							}
						})

					}
				})
			},
			


		},
		

		created () {
			this.GetTableByLine();
			this.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));

		},
		mounted()
		{
			
		},
		
	}
</script>

<style scoped>

</style>