<template>
    <div v-if="$fun.isAdmin()">
        <br>
        <v-row class="pt-3 pr-3 pl-3">
            <h4>Asignar responsable de la Linea </h4>
            <v-spacer></v-spacer>
            <v-btn rounded fab x-small @click="addItemGP()">
                <i class="fas fa-plus"></i>
            </v-btn>   
        </v-row>   
        <v-divider></v-divider>
        <v-row >
            <v-col hidden>
                <s-text
                    label="Linea"
                    v-model="ClpID"
                ></s-text>
            </v-col>
            
            <v-col cols="3" lg="3" md="3" sm="12">		
                <s-toolbar-person
                    v-model="line.LprDocumentNumber"
                    @returnPerson="hadReturnPerson($event)"
                    ref="focusPrsDocument"
                    noCamera
                    noEdit
                />					
            </v-col>
            <v-col cols="4" lg="4" md="4" sm="12">
                <s-text
                    label="Nombres"
                    v-model.trim="newPerson.LprName"
                    ref="focusPgdName"
                ></s-text>
            </v-col>
            <v-col cols="4" lg="4" md="4" sm="12">
                <s-text
                    label="Apellidos"
                    v-model.trim="newPerson.LprLastName"
                ></s-text>
            </v-col>
            <v-col cols="4" lg="4" md="4" sm="12" hidden>
                <s-text
                    label="DNI"
                    v-model.trim="newPerson.LprDocumentNumber"
                ></s-text>
            </v-col>
        </v-row>
        <v-row class="pt-3 pr-3 pl-3">
            <v-col class="pl-0 pt-0">
                <v-data-table
                    dense
                    :headers="headerResponsable"
                    :items="itemsDataPG"
                    hide-default-footer
                    :items-per-page="-1"
                    disable-sort
                >
                    <template v-slot:item.Actions="{ item }">
                        <v-btn
                            @click="clickDeleteItemGP(item)"
                            x-small
                            fab
                            color="error"
                        >
                        <v-icon style="font-size: 16px !important"
                        >mdi-close</v-icon
                        >
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn  
                    color="primary"
                    @click="saveResponsables()">
                    Guardar
                </v-btn> 
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import('../../../styles/linesPacking.css');

    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"
    import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";


    import cTablesLines from "./CTablesLines.vue";



export default {
    components:{SToolbarPerson},
    props: {
        ClpID: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
       
        newPerson: {},
        headerResponsable: [
            { text:"item", value :"Line"},
            { text: "Nombre", value: "LprName"},
            { text: "Apellidos", value: "LprLastName"},
            { text: "dni", value: "LprDocumentNumber"},
            { text: "", value: "Actions" },
        ],
        itemsDataPG:[],
        itemsDeletePG: [],
        line: {},
        item: {},
        
    }),
    
    methods: {
        

        hadReturnPerson(line){
           
            if (line != null) {
                this.newPerson.LprName = line.NtpName.trim();
                this.newPerson.LprLastName = line.NtpPaternalSurname.trim() +" "+ line.NtpMaternalSurname.trim();
                this.newPerson.LprDocumentNumber = line.PrsDocumentNumber;
                

                this.newPerson.ClpID = this.ClpID;
                this.newPerson.UsrCreateID = this.$fun.getUserID();

                this.newPerson.PrsID = line.PrsID;
                this.newPerson.NtpID = line.NtpID;
                // this.newPerson.NtpName = line.NtpName;

            }
     
        },

       

        
        addItemGP(){
            let existPerson = false;
            this.itemsDataPG.forEach(element => {
                if(element.LprDocumentNumber == this.newPerson.LprDocumentNumber){
                    existPerson = true;
                    console.log('trabajador existe ',existPerson)
                }
            });
            if (existPerson){
                this.$fun.alert("Trabajador ya Ingresado", "warning");
                return ;
            }
                if (!this.newPerson.LprName) {
                this.$refs.focusPgdName.error("Debe ingresar el nombre de un Responsable");
                return;
                } else {

                this.newPerson.Line = this.itemsDataPG.length + 1;
                this.newPerson.SecStatus = 1;
                this.itemsDataPG.push({...this.newPerson});
                //console.log("items agregados",this.newPerson)
                this.newPerson = {};

                this.LprName = "";
                this.$refs.focusPgdName.focus();
                
                
            }
        },
        clickDeleteItemGP(item) {
            this.itemsDataPG = this.itemsDataPG.filter(
                (x) => {
                return x.Line != item.Line
                }
            );
            item.SecStatus = 0;
            if(item.LprID > 0)
            {
                this.itemsDeletePG.push({ ...item} ); 
                console.log('eliminados',item );                   
            }
            
        },
        saveResponsables() {

            console.log("guardar responsablke",this.itemsDataPG);

            

            if(this.itemsDataPG.length <= 0 ){
                this.$fun.alert("Grupo debe tener Integrantes", "warning");
                this.$refs.focusPrsDocument.focus();
                return;
            }

            console.log("eliminados", this.itemsDeletePG)

            this.itemsDataPGAdd = [...this.itemsDataPG, ...this.itemsDeletePG];

            
            // this.item.ClpID = this.ClpID;
            let item = {}
            item.ClpID = this.ClpID;
            item.FrzCtnLinesProcessResponsibleDetail = this.itemsDataPGAdd;
            item.FrzCtnLinesProcessResponsibleDetail.forEach(element => {
               
                element.UsrCreateID = this.$fun.getUserID();
                element.UsrUpdateID = this.$fun.getUserID();
            });

            console.log('a gurdar',item);

            this.$fun.alert("Estas seguro de guardar?", "question").then((r) => {
                if(r.value){

                    _sFrzCtnLinesProcessService.saveResponsablesLine(item, this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.$fun.alert("Registrado correctamente", "success");
                        this.$emit("close");
                        this.itemsDataPG = []
                        this.listResponsables();
                    }
                    });
                }
            })
        },
        listResponsables(){
            console.log('listado de integrantes',this.ClpID)
            let obj ={
                ClpID : this.ClpID,
            }
            console.log('obj',obj)
            _sFrzCtnLinesProcessService.listdetail(obj, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    r.data.forEach((el, index) => {
                        el.Line = index + 1;
                    });

                    console.log("detalles", r.data)

                    this.itemsDataPG = r.data;
                }
                
            })
        },
       
        
    },
    created () {
        this.listResponsables();
	},
    mounted(){
        

       
    }

  };

</script>