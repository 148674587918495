<template>
    <div>
        <v-container>
            <br>
            <v-row>
                <!-- <v-col hidden>
                    <s-text
                        label="Linea"
                        v-model="ClpID"
                    ></s-text>
                </v-col> -->
                <!-- <v-col cols="4" lg="4" md="6" sm="12" hidden class="s-col-form">
                    <s-select hidden
                        label="Linea"
                        v-model="PdcID"
                        
                    ></s-select>
                </v-col> -->
                <!-- <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-select
                        :items="ExitCategorys"
                        item-value="CecID"
                        item-text="CecDescription"
                        label="Salida de Tipo de Congelamiento"
                        v-model="line.CecID"
                        :categoryID="line.PdcID"
                        ref="txtCec"
                        @input="inputProdLines($event)"
                    />							
                </v-col> -->
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-select-definition
                        label="Tipo corte"
                        :def="1463"
                        v-model="line.TypeCut"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form" hidden>
                    <s-select-definition
                        label="Estado"
                        v-model="line.ClpState"
                        :def="1154"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-client
                        clearable
                        label="Cliente"
                        v-model="line.CumID"
                        ref="txtCumID"
                        full>
                    </s-select-client>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Tipo de producto"
                        v-model="line.TypeAccordance"
                        :def="1385"
                    ></s-select-definition>
                </v-col>
            </v-row>
            <v-row>
                <!-- <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Cultivo"
                        :def="1173"
                        v-model="line.TypeCultive"
                    ></s-select-definition>
                </v-col> -->
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Tipo Cultivo"
                        :def="1172"
                        v-model="line.TypeCrop"
                    ></s-select-definition>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <label  aria-hidden="true" class="v-label theme--light"><b>Hora inicio de periodo</b></label>
                    <div class="relojito">
                      <datetime valueZone="America/Lima"  type="time" v-model="PprHour">
                      </datetime>
					</div>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-select-definition
                        label="Periodo"
                        v-model="line.PchPeriodo"
                        :def="1444"
                    ></s-select-definition>
                </v-col>
                <v-col v-if="line.TypeAccordance == 2" cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-textarea label="Motivo de No Conformidad" v-model="line.ReasonNonConformity"  ></s-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-btn  
                        v-if="showbuton1"
                        color="primary"
                        @click="endLineProcess()">
                        Agregar Turno
                    </v-btn> 
                    <v-btn  
                        color="primary"
                        @click="startLineProcess()">
                        Iniciar Periodo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pt-3 pr-3 pl-3">
                <v-col class="pl-0 pt-0">
                    <v-data-table
                        dense
                        :headers="headerCliente"
                        :items="itemsDataCli"
                        hide-default-footer
                        :items-per-page="-1"
                        disable-sort
                    >
                    <!-- item-key="PchID"
                    v-model="selected"
                show-select -->
                        <template v-slot:item.Actions="{ item }">
                            <v-btn
                                x-small
                                color="error" 
                                @click="FinalizarPeriodo(item)"
                                v-if="item.PchState != 2"
                            >
                            <!-- @click="clickDeleteItemGP(item)" -->
                            Terminar
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>


        <v-dialog
            v-if="TerminarProcessJabas"
            v-model="TerminarProcessJabas"
            persistent
            width="600"
        >
            <c-jabas-process-end
                @CloseGenerateCutProcess="CloseGenerateCutProcess()"
                @refreshConf="refreshConf()"
                :ClpID="ClpID"
            >
            <!-- :tablesActiveTotal="tablesActiveTotal" -->
            </c-jabas-process-end>
        </v-dialog>

    </div>
</template>

<script>

    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js";
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";

    import cJabasProcessEnd from "./CJabasProcessEnd.vue";

  
  export default {
    components: { SSelectClient,cJabasProcessEnd},
    props: {
        lineaProcess: {
            type: Object,
            required: true,
        },
       
    },
    data: () => ({
        line: {
           
        },
        // ExitCategorys:[],
        headerCliente: [
            { text:"item", value :"Line"},
            { text:"ID Trazabilidad", value :"TraceabilityID"},
            { text: "Cliente", value: "CumNombre"},
            { text: "Tipo Congelamiento", value:"CecDescription", width:"120"},
            { text: "Tipo Corte", value: "TypeCutName"},
            { text: "Cultivo", value: "TypeCultiveName"},
            { text: "Tipo Cultivo", value: "TypeCropName"},
            { text: "Periodo", value: "TypePeriodoName"},
            { text: "Conformidad", value: "TypeAccordanceName"},
            { text: "Hora Inicio", value: "PchDateStart1"},
            { text: "Hora Fin", value: "PchDateEnd1"},
            { text: "", value: "Actions" ,width:"80"},
        ],
        itemsDataCli:[],
        selected: [],
        showbuton1 : false,
        TerminarProcessJabas : false,
        tablesActiveTotal: 0,
        PprHour: "",
        // ClpID:0
        
    }),
    // watch: {
    //     selected(newValue, oldValue)
    //     {
    
    //         this.showbuton = false;
    //         let countHour = 0;
    //         newValue.forEach(element => {
    //             if(element.PchDateEnd != null)
    //             {
    //                 countHour ++;
    //             }
    //         });
    //         if(countHour > 0 || newValue.length == 0)
    //         {
    //             this.showbuton = false;
    //         }
    //         else{
    //             this.showbuton = true;
    //         }
    //     }
    // },
    methods: {
        // inputProdLines(PdcID){   
            // _sFrzCtnLinesProcessService.listExitcatByCat( { PdcID }, this.$fun.getUserID()).then(r =>{
            //     if(r.status == 200)
            //     {
            //         this.ExitCategorys = r.data;         
            
            //     }
            // });   
        // },
       
        
        startLineProcess() {
            
            // if(!this.line.CecID){
            //     this.$fun.alert("Ingrese Tipo de Congelamiento", "warning");
            //     this.$refs.txtCec.focus();
            //     return;
            // }
            if(!this.line.CumID){
                this.$fun.alert("Ingrese Cliente", "warning");
                return;
            }
            if(this.line.TypeAccordance == 1)
            {
                this.line.ReasonNonConformity = '';
            }
            this.$fun.alert("Esta seguro de Iniciar?", "question").then((r) => { // activar o inactivar linea 
            if (r.value) {

                let item ={
                    // ClpID : this.ClpID,
                    // CecID : this.line.CecID,
                    ClpID : this.lineaProcess.ClpID,
                    CecID : this.lineaProcess.CecID,
                    CumID : this.line.CumID,
                    TypeCut : this.line.TypeCut,
                    TypeCultive : this.lineaProcess.TypeCultive,
                    TypeCrop : this.line.TypeCrop,
                    // TrnID      : this.TrnID,
                    UsrCreateID : this.$fun.getUserID(),
                    UsrUpdateID : this.$fun.getUserID(),
                    PchDateStart : this.PprHour,
                    PchPeriodo : this.line.PchPeriodo,
                    TypeAccordance: this.line.TypeAccordance,
                    ReasonNonConformity: this.line.ReasonNonConformity
               }
               
            //    return
            _sFrzCtnLinesProcessService
                .linesStartProcess(item, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        this.$fun.alert("Registrado correctamente", "success");
                        this.$emit("close", this.line);
                        this.modelDialog = false; //verificar
                        this.listConfHour();
                        this.refreshlines()
                    }
                });
            }
            });
		},
        listConfHour(){
            
            
            let obj ={
                ClpID : this.lineaProcess.ClpID,
            }
            
            _sFrzCtnLinesProcessService.listConfHours(obj, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    r.data.forEach((el, index) => {
                        el.Line = index + 1;
                    });
                    
                    this.itemsDataCli = r.data;
                    
                    // if(this.itemsDataCli.length <= 0 ){
                    //     this.showbuton1 = true;
                    // }
                }   
            })
        },
        endLineProcess(){
            
            if(!this.line.CecID){
                this.$fun.alert("Ingrese Tipo de Congelamiento", "warning");
                this.$refs.txtCec.focus();
                return;
            }
            if(!this.line.CumID){
                this.$fun.alert("Ingrese Cliente", "warning");
                // this.$refs.txtCumID.focus();
                return;
            }
            
            this.$fun.alert("Esta seguro de Finalizar?", "question").then((r) => { // activar o inactivar linea 
                if (r.value) {
                    let item ={
                        //PchID : this.itemsDataCli[0].PchID,
                        ClpID : this.lineaProcess.ClpID,
                        CecID : this.lineaProcess.CecID,
                        CumID : this.line.CumID,
                        TypeCut : this.line.TypeCut,
                        TypeCultive : this.line.TypeCultive,
                        TypeCrop : this.line.TypeCrop,
                        UsrCreateID : this.$fun.getUserID(),
                        UsrUpdateID : this.$fun.getUserID()
                    }
                    
                    _sFrzCtnLinesProcessService
                    .linesProcessEnd(item, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Registrado correctamente", "success");
                            this.$emit("close", this.line);
                            this.modelDialog = false; 
                            this.listConfHour();
                        }
                    });
                }
            });
        },
        FinalizarPeriodo(item){
            
            
            this.TerminarProcessJabas = true;
            // this.tablesActiveTotal = [];
            this.ClpID = item.ClpID;
            // this.DtbID = 
            

                // _sFrzCtnLinesProcessService
                // .listTablesByLines({ClpID},this.$fun.getUserID())
                // .then(r => {
                //     if(r.status == 200){
                //         this.tablesActiveTotal.push(...r.data)
                //     }
                // }) 
            
        },
        CloseGenerateCutProcess()
        {   
            this.TerminarProcessJabas = false;
        },
        refreshConf(){
            
            this.listConfHour();
        },
        refreshlines()
        {
            this.$emit("refreshlines");
        },
      
        
        
    },
    created () {
        this.listConfHour();
        this.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
	},
    mounted(){
        
       
    }

  };

</script>