<template>
    <div>
        <!-- <v-container> -->
            <br>
            <v-row>
                <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-text 
                        label="Linea"
                        v-model="ClpID"
                        readonly
                    ></s-text>
                </v-col>
                <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-text 
                        label="Estacion"
                        v-model="CttID"
                        readonly
                    ></s-text>
                </v-col>
                <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-text 
                        label="Turno"
                        v-model="TrnID"
                        readonly
                    ></s-text>						
                </v-col>
                <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">	
                    <s-text 
                        label="Tipo Cultivo"
                        v-model="TypeCultive"
                        readonly
                    ></s-text>	
                </v-col>
                <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <s-text 
                        label="PND"
                        v-model="PdgID"
                        readonly
                    ></s-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <s-text 
                        label="Contador inicial"
                        v-model="itemHead.CmhNumbersMangoInitial"
                        number
                        :min=0
                    ></s-text>
                </v-col>
                <v-col  cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <s-text 
                        label="Contador final"
                        v-model="itemHead.CmhNumbersMangoUltimate"
                        number
                        :min=0
                    ></s-text>
                </v-col>
                <v-col  cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <s-text 
                        label="Cantidad de Jabas"
                        v-model="itemHead.CmhJabaMango"
                        number
                        :min=0
                    ></s-text>
                </v-col>
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <v-btn
                        color="success"
                        
                        @click="saveAddMangosHours()"
                    > Guardar</v-btn>
                </v-col>
            </v-row>

            
            
            <v-row>
                <v-col>
                    <v-data-table
                        dense
                        :headers="headerMangoHour"
                        :items="itemsMangoHour"
                        hide-default-footer
                        :items-per-page="-1"
                        disable-sort
                    >
                    <!-- item-key="PchID" -->
                        
                        <template v-slot:item.Edit="{ item }">
                            <v-btn
                                @click="clickEdit(item)"
                                x-small
                                fab
                                color="info"
                            >
                            <v-icon style="font-size: 16px !important">mdi-pen</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.Actions="{ item }">
                            <v-btn
                                @click="clickDelete(item)"
                                x-small
                                fab
                                color="error"
                            >
                            <v-icon style="font-size: 16px !important">mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        <!-- </v-container> -->

        <v-dialog
            v-if="DialogEditjabas"
            v-model="DialogEditjabas"
            persistent 
            width="500"
        >
            <v-card>
                <s-toolbar  
                    color="primary"                  
                    dark
                    close
                    save
                    @save="InsertJaba()"
                    @close="DialogEditjabas = false"
                    label="Editar numero de Jabas"
                >
                </s-toolbar>
                <v-card-text>
                    <v-row style="margin-top: 0.1em;">
                        <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">
                            <s-text 
                                v-model="itemHead.CmhID"
                                number
                                :min=0
                            ></s-text>
                        </v-col>
                        <v-col  cols="4" lg="4" md="6" sm="12" class="s-col-form">
                            <s-text 
                                label="Numero de Jabas"
                                v-model="itemHead.CmhJabaMango"
                                number
                                :min=0
                            ></s-text>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>

    import _sFrzCtnMangosHours from "@/services/FrozenProduction/FrzCtnMangosHoursService.js";
    
    import _sParameter from "@/services/General/ParameterService.js";
   
  
  export default {
    components: { },
    props: {
        ClpID: {
            type: Number,
            required: true,
        },
        CttID: {
            type: Number,
            required: true,
        },
        TrnID: {
            type: Number,
            required: true,
        },
        TypeCultive: {
            type: Number,
            required: true,
        },
        PdgID: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        line: {},
        headerMangoHour: [
            { text:"ID", value :"CmhID"},
            { text: "Linea", value: "LncDescription"},
            { text: "PND", value: "PdgNameGroup"},
            { text: "Estacion", value:"CttName"},
            { text: "Turno", value: "TnrObservation"},
            { text: "Tipo Cultivo", value: "DedDescription"},
            { text: "Contador Inicial", value: "CmhNumbersMangoInitial"},
            { text: "Contador Final", value : "CmhNumbersMangoUltimate"},
            // { text: "Periodo", value: "PchID"},
            { text: "N° de Mangos", value: "CmhNumbersMango"},
            { text: "N° Jabas", value: "CmhJabaMango"},
            { text: "Editar", value: "Edit" ,width:"80"},
            { text: "Borrar", value: "Actions" ,width:"80"},
        ],
        itemsMangoHour:[],
        itemHead:{},
        capacityJaba: 0,
        DialogEditjabas : false,

        
    }),
    methods: {
        
        saveAddMangosHours(){
            console.log('Guardar mangos por Horas')
           
            if(!this.itemHead.CmhNumbersMangoInitial){
                this.$fun.alert("Ingresar Cantidad Inicial de Mangos", "warning")
                    
                return;
            }
            if(!this.itemHead.CmhNumbersMangoUltimate){
                this.$fun.alert("Ingresar Cantidad Final de Mangos", "warning")
                    
                return;
            }            
            // let totalJaba = 0;
            // totalJaba = Math.ceil (this.itemHead.CmhNumbersMango/this.capacityJaba);
            // console.log ('total jabas',totalJaba)
            // console.log ('total jabas fisicas',Math.ceil(totalJaba));
            let item = {
                ClpID : this.ClpID ,
                CttID : this.CttID ,
                TrnID : this.TrnID ,
                TypeCultive : this.TypeCultive ,
                PdgID : this.PdgID,
                CmhNumbersMangoInitial : this.itemHead.CmhNumbersMangoInitial,
                CmhNumbersMangoUltimate : this.itemHead.CmhNumbersMangoUltimate,
                CmhJabaMango : this.itemHead.CmhJabaMango, //totalJaba,
                UsrCreateID: this.$fun.getUserID(),
            }
            console.log('Guardar',item);
            // return
            this.$fun.alert("¿Esta seguro de guardar?", "question").then((r) => {
                if (r.value) {
                    _sFrzCtnMangosHours.saveMangoHours( item, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Guardado correctamente", "success")
                            this.Pagination();
                        }
                    })    
                } 
            })
        },
        Pagination(){
            let obj ={
                PdgID : this.PdgID,
                TrnID : this.TrnID,
            }

            _sFrzCtnMangosHours.pagination(obj, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    if (r.status == 200) {
                    this.itemsMangoHour = r.data;
                    }
                }
            });
        },
        clickDelete(item){
            console.log('Elimnar registro',item);
            let val ={
                CmhID : item.CmhID,
                UsrUpdateID: this.$fun.getUserID(),
            }
            console.log('Elim',val);
            this.$fun.alert("¿Esta seguro de Eliminar?", "question").then((r) => {
                if (r.value) {
                    _sFrzCtnMangosHours.UpdMangoHours(val, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if (r.status == 200) {
                                this.itemID = r.data;
                                this.$fun.alert("Eliminado correctamente", "success")
                                this.Pagination();
                            }
                        }
                    });
                } 
            })
        },
        clickEdit(item){
            // console.log('Editando',item);
            this.DialogEditjabas = true ;
            let x = (item.CmhID)
            let numJabas = (item.CmhJabaMango)
            this.itemHead.CmhID = x;
            this.itemHead.CmhJabaMango = numJabas;
            console.log(x)
           
        },
        InsertJaba(){

            let obj={
                CmhID : this.itemHead.CmhID,
                CmhJabaMango : this.itemHead.CmhJabaMango,
                UsrUpdateID : this.$fun.getUserID(),
            }
            console.log('obj a modificar',obj);
            // return
            this.$fun.alert("¿Esta seguro de modificar?", "question").then((r) => {
                if (r.value) {
                    _sFrzCtnMangosHours.UpdMangoHours(obj, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if (r.status == 200) {
                                this.itemID = r.data;
                                this.$fun.alert("Modificado correctamente", "success")
                                this.Pagination();
                                this.DialogEditjabas = false ;
                            }
                        }
                    });
                } 
            })
        }
       
        
        
            
        
      
        
        
    },
    created () {
        _sParameter.search({ PrmName: "capacidadJabaMangoAcondicionado"}, this.$fun.getUserID()).then((r) => {
               
            this.capacityJaba  = r.data.PrmValue ?? 0;
        });

        this.Pagination();
	},
    mounted(){
        
       
    }

  };

</script>