<template>
	<div>
		<v-card>
			<v-card-title>
				Generar Corte
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col
						lg="3"
						md="3"
						sm="6"
						xs="12"
						v-for="(item, index) in listTables"
						:key="index"
					>
						<s-text
							:label="item.CttName"
							v-model="item.LpcQuantityJaba"
							number
							:min="0"
						></s-text>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="success"
					text
					@click="SaveCutProcess()"
				>
					Guardar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="orange"
					text
					@click="CloseGenerateCutProcess()"
				>
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	import _scutService from "@/services/FrozenProduction/FrzCtnLinesProcessCutService.js"

	export default {
		props: {
			tablesActiveTotal: {},
		},

		data() {
			return {
				listTables: []
			}
		},

		methods: {
			CloseGenerateCutProcess() {
				this.$emit("CloseGenerateCutProcess");
			},

			SaveCutProcess()
			{
				this.$fun.alert("¿Seguro de realizar corte?", "question")
				.then(r => {
					if(r.value)
					{
						this.listTables.forEach(element => {
							element.UsrCreateID = this.$fun.getUserID();
						});
						
						_scutService
						.save(this.listTables, this.$fun.getUserID())
						this.$fun.alert("Corte realizado correctamente", "success");
						this.CloseGenerateCutProcess();
						return;
					}
				})
			}
		},

		created () {
			this.listTables = this.tablesActiveTotal;
		},
	}
</script>

<style scoped>

</style>