import Service from "../Service";
const resource = "frzctnlineasprocessstop/";

export default {

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

	lasstopbyline(prd, requestID) {
        return Service.post(resource + "lasstopbyline", prd, {
            params: { requestID: requestID },
        });
    },

};