import Service from "../Service";
const resource = "frzctnlinesprocesscut/";

export default {

    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    getoutputweihgt(prd, requestID){
        return Service.post(resource + "outputweihgtconditioned", prd, {
            params: { requestID: requestID },
        });
    }
};