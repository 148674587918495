<template>
	<div>
		<v-card
			dense
			outlined
		>
			<v-card-title>
				Paradas Linea
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col class="s-col-form">
						<s-date-time
							label="fecha"
							v-model="stop.LpsDate"
						></s-date-time>
					</v-col>
					<v-col class="s-col-form">
						<s-select-definition
							label="Tipo Parada"
							:def="1432"
							v-model="stop.TypeCategory"
						></s-select-definition>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<!-- <s-text
							label="Responsable"
							v-model="stop.LpsResponsible"
						></s-text> -->
						<s-select
							label="Responsable"
							:items="itemResponsible"
							item-text="CrpDescription"
							item-value="CrpID"
							v-model="stop.CrpID"
							clearable
							@input="getReason($event)"
						>
						</s-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="s-col-form">
						<!-- <s-textarea
							label="Descripción"
							v-model="stop.LpsReason"
						></s-textarea> -->
						<s-select
							label="Motivo"
							:items="itemReason"
							item-text="RsdReason"
							item-value="RsdID"
							v-model="stop.RsdID"
							clearable
						>
						</s-select>
					</v-col>
					<v-col class="s-col-form">
						<s-text
							label="N° Personas Indirectas"
							v-model="stop.LpsPersonIndirect"
							clearable
						>
						</s-text>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn
					color="success"
					text
					@click="saveStop()"
				>
					Guardar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="orange"
					text
					@click="closeDialogStop()"
				>
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
	import _sStop from "@/services/FrozenProduction/FrzCtnLinesProcessStop.js"
	import _sFrzCtnResponsibleStopService from "@/services/FrozenProduction/FrzCtnResponsibleStopService.js";


	export default {
		props: {
			lineSelected: {},
		},

		data() {
			return {
				stop: {},
				itemResponsible: [],
				itemReason: [],

			}
		},

		methods: {
			closeDialogStop() {
				this.$emit("closeDialogStop");
			},

			saveStop()
			{
				
				this.$fun.alert("¿Seguro de pausar?", "question")
				.then(r =>{
					if(r.value)
					{
						this.stop.LpsID = 0;
						this.stop.ClpID = this.lineSelected.ClpID;
						this.stop.TypePlace = 1;
						this.stop.SecStatus = 1;
						this.stop.UsrCreateID = this.$fun.getUserID();
						this.stop.UsrUpdateID = this.$fun.getUserID();

						_sStop
						.save(this.stop, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Pausado correctamente", "success");
								this.closeDialogStop();
								this.$emit("refreshlines");
								return;
							}
						})
					}
				})
			},
			ListResponsible(){
                _sFrzCtnResponsibleStopService.listResponsible({},this.$fun.getUserID()) 
                .then( resp => {
                    if(resp.status == 200)
                    {
                        this.itemResponsible = resp.data;                        
                    }
                })   
            },
			getReason(item){
				
				if(item > 0){

					let CrpID ={
						CrpID : item
					}
					_sFrzCtnResponsibleStopService.listdetail(CrpID,this.$fun.getUserID()) 
					.then( resp => {
						if(resp.status == 200)
						{
							this.itemReason = resp.data;                        
						}
					})
				}

			},
			
		},
		created(){
			this.ListResponsible();
		}

	}
</script>

<style scoped>

</style>