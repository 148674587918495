import Service from "../Service";
const resource = "FrzCtnMangosHours/";

export default {

    saveMangoHours(prd, requestID) {
        return Service.post(resource + "saveMangoHours", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(prd, requestID) {
        return Service.post(resource + "pagination", prd, {
            params: { requestID: requestID },
        }); 
    },
    UpdMangoHours(prd, requestID) {
        return Service.post(resource + "UpdMangoHours", prd, {
            params: { requestID: requestID },
        });
    },


}
