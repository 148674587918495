<template>
    <div>
        <v-container>  
            <v-row>
                <!-- <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <s-text v-model="PrsID">

                    </s-text>
                </v-col> -->

                <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <label  aria-hidden="true" class="v-label theme--light"><b>Hora Inicio</b></label>
                    <div class="relojito">
                      <datetime valueZone="America/Lima"  type="time" v-model="CmmHourInitial">
                      </datetime>
					</div>
                </v-col>

                <v-col cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <label  aria-hidden="true" class="v-label theme--light"><b>Hora Fin</b></label>
                    <div class="relojito">
                      <datetime valueZone="America/Lima"  type="time" v-model="CmmHourEnd">
                      </datetime>
					</div>
                </v-col>

                <v-col  cols="4" lg="4" md="4" sm="12" class="s-col-form">
                    <s-text 
                        label="N° Mangos por Minuto"
                        v-model="itemHead.CmmNumbersMango"
                        number
                        :min=0
                    ></s-text>
                </v-col>
            </v-row>
            <v-row>
                
                <v-col cols="4" lg="4" md="6" sm="12" class="s-col-form">
                    <v-btn
                        color="info"
                        @click="saveAddMangosMinute()"
                    > Guardar</v-btn>
                </v-col>
            </v-row>
            
            <v-row class="pt-3 pr-3 pl-3">
                <v-col class="pl-0 pt-0">
                    <v-data-table
                        dense
                        :headers="headerMedicionMinuto"
                        :items="itemsMedicionMinuto"
                        hide-default-footer
                        :items-per-page="-1"
                        disable-sort
                    >
                    <!-- item-key="PchID" -->
                        
                        <template v-slot:item.Edit="{ item }">
                            <v-btn
                                @click="clickEdit(item)"
                                x-small
                                fab
                                color="info"
                            >
                            <v-icon style="font-size: 16px !important">mdi-pen</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.Actions="{ item }">
                            <v-btn
                                @click="clickDelete(item)"
                                x-small
                                fab
                                color="error"
                            >
                            <v-icon style="font-size: 16px !important">mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog
            v-if="DialogEditMedicion"
            v-model="DialogEditMedicion"
            persistent 
            width="500"
        >
            <v-card>
                <s-toolbar  
                    color="primary"                  
                    dark
                    close
                    save
                    @save="InsertMangos()"
                    @close="DialogEditMedicion = false"
                    label="Numero de Mangos"
                >
                </s-toolbar>
                <v-card-text>
                    <v-row style="margin-top: 0.1em;">
                        <v-col hidden cols="4" lg="4" md="6" sm="12" class="s-col-form">
                            <s-text 
                                v-model="itemHead.CmmID"
                                number
                                :min=0
                            ></s-text>
                        </v-col>
                        <v-col  cols="4" lg="4" md="6" sm="12" class="s-col-form">
                            <s-text 
                                label="Numero de Mangos"
                                v-model="itemHead.CmmNumbersMango"
                                number
                                :min=0
                            ></s-text>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>

    import _sFrzCtnMeasurementMinute from "@/services/FrozenProduction/FrzCtnMeasurementMinuteService.js";
    
   
  
  export default {
    components: { },
    props: {
        ClpID: {
            type: Number,
            required: true,
        },
        CttID: {
            type: Number,
            required: true,
        },
        TrnID: {
            type: Number,
            required: true,
        },
        TypeCultive: {
            type: Number,
            required: true,
        },
        PdgID: {
            type: Number,
            required: true,
        },
        PgdID:{
            type: Number,
            required: false,
        },
        TypeRoll:{
            type: Number,
            required: true,
        },
        PrsID:{
            type:Number,
            required: true,
        }
    },
    data: () => ({
        line: {},
        headerMedicionMinuto: [
            { text:"ID", value :"CmmID"},
            { text:"Trabajador", value: "PgdDatosPerson"},
            { text:"Rol", value: "TypeRollName"},
            { text: "Linea", value: "LncDescription"},
            { text: "Mesa", value: "PdgNameGroup"},
            { text: "Estacion", value:"CttName"},
            { text: "Turno", value: "TnrObservation"},
            { text: "Tipo Cultivo", value: "DedDescription"},
            { text: "Hora Inicio", value: "CmmHourInitialN"},
            { text: "Hora Fin", value : "CmmHourEndN"},
            { text: "N° de Mangos", value: "CmmNumbersMango"},
            { text: "Editar", value: "Edit" ,width:"80"},
            { text: "Borrar", value: "Actions" ,width:"80"},
        ],
        itemsMedicionMinuto:[],
        itemHead:{},
        CmmHourInitial: "",
        CmmHourEnd: "",
        DialogEditMedicion : false,

        
    }),
    methods: {
        
        saveAddMangosMinute(){
            console.log('Medicion por minutos - mangos')
           
            if(!this.CmmHourInitial){
                this.$fun.alert("Ingresar hora Inicial", "warning")
                return;
            }
            if(!this.CmmHourEnd){
                this.$fun.alert("Ingresar hora Final", "warning")
                return;
            }            
            let item = {
                ClpID : this.ClpID ,
                CttID : this.CttID ,
                TrnID : this.TrnID ,
                TypeCultive : this.TypeCultive ,
                PdgID : this.PdgID,
                PgdID : this.PgdID,
                PrsID : this.PrsID,
                TypeRoll : this.TypeRoll ,
                CmmHourInitial : this.CmmHourInitial,
                CmmHourEnd : this.CmmHourEnd,
                CmmNumbersMango : this.itemHead.CmmNumbersMango,
                UsrCreateID: this.$fun.getUserID(),
            }
            console.log('Guardar',item);
            // return
            this.$fun.alert("¿Esta seguro de guardar?", "question").then((r) => {
                if (r.value) {
                    _sFrzCtnMeasurementMinute.save( item, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.$fun.alert("Guardado correctamente", "success")
                            this.Pagination();
                        }
                    })    
                } 
            })
        },
        Pagination(){
            console.log('paginacion');
            let obj ={
                PgdID : this.PgdID,
                TrnID : this.TrnID,
            }
            console.log('paginacion', obj);
            // return
            _sFrzCtnMeasurementMinute.pagination(obj, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    if (r.status == 200) {
                    this.itemsMedicionMinuto = r.data;
                    }
                }
            });
        },
        clickDelete(item){
            console.log('Elimnar registro',item);
            let val ={
                CmmID : item.CmmID,
                UsrUpdateID: this.$fun.getUserID(),
            }
            console.log('Elim',val);
            this.$fun.alert("¿Esta seguro de Eliminar?", "question").then((r) => {
                if (r.value) {
                    _sFrzCtnMeasurementMinute.Update(val, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if (r.status == 200) {
                                this.itemID = r.data;
                                this.$fun.alert("Eliminado correctamente", "success")
                                this.Pagination();
                            }
                        }
                    });
                } 
            })
        },
        clickEdit(item){
            // console.log('Editando',item);
            this.DialogEditMedicion = true ;
            let x = (item.CmmID)
            let numMangos = (item.CmmNumbersMango)
            this.itemHead.CmmID = x;
            this.itemHead.CmmNumbersMango = numMangos;
            console.log(x)
           
        },
        InsertMangos(){

            let obj={
                CmmID : this.itemHead.CmmID,
                CmmNumbersMango : this.itemHead.CmmNumbersMango,
                UsrUpdateID : this.$fun.getUserID(),
            }
            console.log('obj a modificar',obj);
            // return
            this.$fun.alert("¿Esta seguro de modificar?", "question").then((r) => {
                if (r.value) {
                    _sFrzCtnMeasurementMinute.Update(obj, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if (r.status == 200) {
                                this.itemID = r.data;
                                this.$fun.alert("Modificado correctamente", "success")
                                this.Pagination();
                                this.DialogEditMedicion = false ;
                            }
                        }
                    });
                } 
            })
        }
       
        
        
            
        
      
        
        
    },
    created () {
        this.CmmHourInitial = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
        this.CmmHourEnd = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
        this.Pagination();
	},
    mounted(){
        
       
    }

  };

</script>