import Service from "../Service";

const resource = "FrzCtnDetailLinesProcess/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
    list(obj,requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },
    transferGroup(obj,requestID) {
        return Service.post(resource + "transferGroup", obj, {
            params: { requestID: requestID },
        });
    },

}