<template>    
    <div>    
        <v-card>
            <v-card-title>Envio de Kg a Linea</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <s-select
                            label ="Periodo de Produccion"
                            v-model="PchID"
                            :items="itemPeriodo"
                            item-value="PchID"
                            item-text="PchPeriodo"
                            ref="txtPchPeriodo"
                            clearable
                        >
                        </s-select>
                    </v-col>
                    <v-col>
                        <s-select
                            label="N° de Lote "
                            v-model="newRcfID"
                            return-object
                            :items="itemRcfid"
                            item-value="DtbID"
                            item-text="RcfDate"
                            ref="txtnewRcfID"
                            clearable
                        >
                        </s-select>
                    </v-col>
                    <v-col>
                        <s-text
                            label="Kilogramos"
                            decimal
                            v-model="QuantityKg"
                            ref="txtQuantityKg"
                        >
                            
                        </s-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-radio-group
                            row
                            hide-details
                            v-model="lineSelected">
                            <v-radio
                                v-for="line in linesProduction"
                                :key="line.ClpID"
                                :label="line.LncDescription"
                                :value="line.ClpID">

                            </v-radio>
                        </v-radio-group>
                    </v-col>
                
                    <v-col  class="pl-5 pt-6" >
                        <v-btn class="s-col-form"
                       
                            color="success"
                            text 
                            outlined
                            @click="SaveKgLine()">
                            Guardar

                        </v-btn>
                    </v-col>
                </v-row>  
                         
            </v-card-text>
            <v-card-actions>
                
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                        dense 
                        :headers="headersTable"
                        :items="itemsTable"
                        hide-default-footer
                        :items-per-page="-1"
                        disable-sort >
                            <template v-slot:item.Actions="{item}">
                                <v-btn
                                x-small
                                fab
                                rounded
                                color="error" 
                                @click="DeleteKg(item)"
                                v-if="item.SecStatus != 2"
                                >
                                <v-icon style="font-size:10px !important">
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>    
                
            </v-card-actions>
        </v-card>           
    </div>
</template>

<script>
    import TablesLine from '@/components/FrozenProduction/Aconditionado/TablesLine.vue';
    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"
    import _FrzCtnSendKgLinesProcess from "@/services/FrozenProduction/FrzCtnSendKgLinesProcess.js"

    import _sProcessSampling from '@/services/FrozenProduction/ProcessSamplingService';
import ConfigTreatment from '../WashDisinfect/ConfigTreatment.vue';


    export default {
        components: {TablesLine},
        props:{
            ClpID: {
                type: Number,
                required: true,
            },
        },
        data(){
            return {
                QuantityKg: 0,
                linesProduction: [],
                lineSelected: 0,
                filter:{
                    TypeCultive: 1,
                    
                },
                headersTable: [
                    { text: "ID", value: "LpcID"},
                    { text: "Linea Origen", value: "ClpIDOldName"},
                    { text: "Linea Destino", value: "ClpIDNewName"},
                    { text: "Total Kg.", value: "AverageWeightJabaN"},
                    { text: "Hora", value: "HourDate"},
                    // { text: "Estado", value: "SecStatus"},
                    { text: "", value: "Actions"},
                ],
                itemsTable: [],
                PchID:0,
                newRcfID:{},
                itemPeriodo:[],
                itemRcfid:[],
            }
        },
        methods:{

            getLinesProduction(){
                _sFrzCtnLinesProcessService.linesactive(  this.filter , this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						
						this.linesProduction = resp.data;
						
					}
				})
            },
            SaveKgLine(){
                if(this.PchID == ''){
                    this.$fun.alert("Seleccione periodo de producción","warning");
                    this.$refs.txtPchPeriodo.focus();
                    return
                }
                
                if(this.newRcfID.RcfID == undefined){
                    this.$fun.alert("Seleccione Lote de Produccion","warning");
                    this.$refs.txtnewRcfID.focus();
                    return
                }
                if(this.QuantityKg == ''){
                    this.$fun.alert("Ingrese cantidad de Kg","warning");
                    this.$refs.txtQuantityKg.focus();
                    return
                }
                if(this.ClpID == this.lineSelected)
                {
                    this.$fun.alert("No se puede enviar a la misma linea", "info");
                    return;
                }
                if(this.lineSelected == 0 || this.lineSelected == undefined)
                {
                    this.$fun.alert("Debe seleccionar a que linea de enviaran los Kg", "info");
                    return;
                }
                let items = {
                    ClpID: this.ClpID,
                    AverageWeightJabaN : this.QuantityKg,
                    ClpIDNew: this.lineSelected,
                    UsrID: this.$fun.getUserID(),
                    PchID : this.PchID,
                    RcfID : this.newRcfID.RcfID,
                }
                
                // return

                this.$fun.alert("¿Desea guardar la informacion?", "question")
                .then( r => {
                    if(r.value)
                    {
                        _FrzCtnSendKgLinesProcess.save(items, this.$fun.getUserID())
                        .then( resp => {
                            if(resp.status == 200)
                            {
                                this.$fun.alert("Se registro Correctamente", "success");
                                this.getSendKgLinesProduction();
                            }
                        })
                    }
                });
                
            },

            getSendKgLinesProduction(){
                _FrzCtnSendKgLinesProcess.list({ ClpID: this.ClpID}, this.$fun.getUserID()) 
                .then( resp => {
                    if(resp.status == 200)
                    {
                        this.itemsTable = resp.data;                        
                    }
                })        
            },
            DeleteKg(item){
                
            
                this.$fun.alert("¿Desea eliminar?", "question")
                .then( r => {
                    if(r.value)
                    {
                        let items = {
                            LpcID : item.LpcID,
                            UsrUpdateID : this.$fun.getUserID(),
                        }
                        
                        _FrzCtnSendKgLinesProcess.update(items, this.$fun.getUserID())
                        .then( resp => {
                            if(resp.status == 200)
                            {
                                this.$fun.alert("Se registro Correctamente", "success");
                                this.getSendKgLinesProduction();
                            }
                        })
                    }
                });
            },
            listperiodoActivo(){
                _sFrzCtnLinesProcessService.listPeriodoActivo({ ClpID: this.ClpID}, this.$fun.getUserID()) 
                .then( resp => {
                    if(resp.status == 200)
                    {
                        this.itemPeriodo = resp.data;                        
                    }
                })   
            },
            LoteProduccion(){
                let filter = {
                    // DtbID: this.DtbID,
                    // TypeCultive: this.TypeCultive
                    ClpID : this.ClpID,//3319,
                    DtbID : this.DtbID

                }
                
                // return
                _sProcessSampling.lst(filter, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemRcfid = r.data;   
                                                 
                    }
                });
            },
        },
        mounted()
        {
            this.getLinesProduction();
        },
        created(){
            this.getSendKgLinesProduction();
            this.listperiodoActivo();
            this.LoteProduccion();
        }
    }


</script>